<template>
	<PaysheetForm :id="id" :is-update="isUpdate" @submit="updatePaysheet" @cancel="gotoList" />
</template>

<script>
import { PARAMS, PAYSHEET_TABLE } from "../../../shared/plugins/constants"
import { createNamespacedHelpers, mapState } from "vuex"
import { GET_TYPE_OF_PAYSHEET } from "../../typeOrder/store/action-types"
import { GET_EDITORS_IN_PAYSHEET, UPDATE_PAYSHEET } from "../store/action-types"
import { GET_EDITORS_NOT_PAYSHEET } from "../../editors/store/action-types"
import { RESET_STATE_PAYSHEET, SET_EDITOR_SELECTED } from "../store/mutation-types"
import { RESET_STATE } from "../../typeOrder/store/mutation-types"
import { RESET_STATE_EDITOR } from "../../editors/store/mutation-types"
const {
	mapActions: typeOrderActions,
	mapState: typeOrderState,
	mapMutations: typeOrderMuatations,
} = createNamespacedHelpers("typeOrder")
const {
	mapActions: editorsActions,
	mapState: editorsState,
	mapMutations: editorsMutations,
} = createNamespacedHelpers("editors")
const {
	mapActions,
	mapState: paysheetState,
	mapMutations: paysheetMutations,
} = createNamespacedHelpers("paysheet")
export default {
	name: "UpdatePaysheet",
	components: {
		PaysheetForm: () => import("../componetns/PaysheetForm"),
	},
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			offsetType: PARAMS.OFFSET,
		}
	},
	computed: {
		...typeOrderState(["recentFilters"]),
		...editorsState({ recentFiltersEditor: "recentFilters" }),
		...paysheetState(["recentFiltersSelected", "listEditorSelected"]),
		...mapState(["pageChanged"]),
	},
	watch: {
		pageChanged(val) {
			if (val) {
				switch (val.tableName) {
					case PAYSHEET_TABLE.WAGE:
						this.offsetType = val.offset
						this.getOrderType()
						break
					default:
						break
				}
			}
		},
	},
	created() {
		this.setupData()
	},
	beforeDestroy() {
		this.$store.commit("set", ["pageChanged", null])
		this.RESET_STATE()
		this.RESET_STATE_EDITOR()
		this.RESET_STATE_PAYSHEET()
	},
	methods: {
		...typeOrderActions({ GET_TYPE_OF_PAYSHEET }),
		...mapActions({ GET_EDITORS_IN_PAYSHEET, UPDATE_PAYSHEET }),
		...editorsActions({ GET_EDITORS_NOT_PAYSHEET }),
		...paysheetMutations({ SET_EDITOR_SELECTED, RESET_STATE_PAYSHEET }),
		...typeOrderMuatations({ RESET_STATE }),
		...editorsMutations({ RESET_STATE_EDITOR }),
		async setupData() {
			this.showLoading()
			await Promise.all[
				(this.getEditorsInPaysheet(), this.getOrderType(), this.getEditorsNotInPaysheet())
			]
			this.hideLoading()
		},
		async getOrderType() {
			const params = {
				data: { ...this.recentFilters, offset: this.offsetType, paySheetId: this.id },
				notLoading: true,
			}
			await this.GET_TYPE_OF_PAYSHEET(params)
		},
		async getEditorsInPaysheet() {
			const params = {
				isSort: true,
				paySheetId: this.id,
			}
			await this.GET_EDITORS_IN_PAYSHEET(params)
		},
		async getEditorsNotInPaysheet() {
			const params = {
				isSort: true,
			}
			await this.GET_EDITORS_NOT_PAYSHEET(params)
		},
		gotoList() {
			this.$router.go(-1)
		},
		async updatePaysheet(params) {
			await this.UPDATE_PAYSHEET(params)
		},
	},
}
</script>

<style></style>
